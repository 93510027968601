<template>
  <div class="row">
    <div class="small-8 columns with-right-space">
      <table v-if="hasConnectedHooks" class="woot-table">
        <thead>
          <th v-for="hookHeader in hookHeaders" :key="hookHeader">
            {{ hookHeader === "percentageLoading" ? " " : hookHeader }}
          </th>
          <th>
            {{ $t('INTEGRATION_APPS.LIST.CATALOG') }}
          </th>
        </thead>
        <tbody>
          <tr v-for="hook in hooks" :key="hook.id">
            <td
              v-for="property in hook.properties"
              :key="property"
              class="hook-item"
            >
              {{ property }}
            </td>
            <td class="button-wrapper">
              <woot-button
                v-tooltip.top="
                  $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.BUTTONS.EDIT')
                "
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="edit"
                class-names="success"
                @click="openEditTemplate(hook)"
              />
              <woot-button
                v-tooltip.top="
                  $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.BUTTONS.LIST')
                  "
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                class-names="grey-btn"
                icon="upload"
                @click="openListCatalog(hook)"
              />
              <woot-button
                v-tooltip.top="                  
                $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.BUTTONS.DELETE')
"
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="dismiss-circle"
                class-names="grey-btn"
                @click="$emit('delete', hook)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-items-error-message">
        {{
          $t('INTEGRATION_APPS.NO_HOOK_CONFIGURED', {
            integrationId: this.labelContentEmpty,
          })
        }}
      </p>
    </div>
    <div class="small-4 columns">
      <p>
        <b>{{ integration.name }}</b>
      </p>
      <p
        v-dompurify-html="
          $t(
            `INTEGRATION_APPS.SIDEBAR_DESCRIPTION.${integration.name.toUpperCase()}`,
            { installationName: globalConfig.installationName }
          )
        "
      />
    </div>
    <div v-if="showListModal" @click="closeEditTemplate">
      <woot-modal
        :show.sync="showListModal"
        :on-close="closeEditTemplate"
        :persistent="true"
        size="modal-medium"
        @click.stop
      >
        <woot-modal-header
          :header-title="pageTitle"
          :info-button="pageTitle"
        />
        <form class="row medium-8" @submit.prevent="editTemplateCatalog">
          <suggestion-buttons
            :suggestions="suggestions"
            @customVariable="customVariable"
          />
          <div class="medium-12 columns">
            <label class="columns medium-8 large-8">
              {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
            </label>
            <div class="editor-wrap">
              <woot-message-editor
                v-model="contentEdit"
                class="message-editor"
                :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              />
            </div>
          </div>
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                :disabled="$v.contentEdit.$invalid"
                :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              />
              <button class="button clear" @click="closeEditTemplate">
                {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
              </button>
            </div>
          </div>
        </form>
      </woot-modal>
    </div>

    <div v-if="showTemplateModal" @click="closeTemplateModal">
      <list-product-catalog
        class="main-container-catalog-modal"
        :show-template-modal="showTemplateModal"
        :catalog-product-headers="catalogProductHeaders"
        :is-loading="false"
        :selected-hook="selectedHook"
        @on-close="closeTemplateModal"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import hookMixin from '../hookMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import ListProductCatalog from './ListProductCatalog.vue';
import SuggestionButtons from '../../canned/SuggestionButtons.vue';
import { MESSAGE_CATALOG_PRODUCT } from 'shared/constants/messages';

export default {
  components: {
    SuggestionButtons,
    WootMessageEditor,
    ListProductCatalog,
  },
  mixins: [hookMixin],
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      textList: '',
      showListModal: false,
      alertMessage: '',
      showTemplateModal: '',
      contentEdit: '',
      title: '',
      selectedHook: {},
    };
  },
  validations: {
    contentEdit: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
    }),
    ...mapActions({
      updateHookeSettings: 'integrations/updateHookeSettings',
    }),
    pageTitle() {
      return `${this.$t('INTEGRATION_APPS.TEMPLATE.EDIT')} - ${this.title}`;
    },
    suggestions() {
      return MESSAGE_CATALOG_PRODUCT;
    },
    catalogProductHeaders() {
      return this.integration.catalog_product_properties;
    },
    hookHeaders() {
      return this.integration.visible_properties;
    },
    hooks() {
      if (!this.hasConnectedHooks) {
        return [];
      }
      const { hooks } = this.integration;
      return hooks.map(hook => ({
        ...hook,
        id: hook.id,
        properties: this.hookHeaders.map(property =>
          hook.settings[property] ? hook.settings[property] : '--'
        ),
      }));
    },
    labelContentEmpty() {
      return this.integration.id === 'catalog'
        ? this.$t('INTEGRATION_APPS.CATALOG')
        : this.integration.id;
    },
  },
  methods: {
    async editTemplateCatalog() {
      const payload = {
        settings: {
          template: this.contentEdit,
        },
      };
      try {
        await this.$store.dispatch('integrations/updateHookeSettings', {
          hookId: this.selectedHook.id,
          data: payload,
        });
        this.showAlert('Settings updated successfully!');
        this.closeEditTemplate();
      } catch (error) {
        let errorMessage =
          error?.response?.data?.message || 'Failed to update settings.';
        this.showAlert(errorMessage);
      }
    },
    customVariable(value) {
      this.contentEdit += ` {{${value}}}&nbsp;`;
    },
    showAlert(message) {
      this.alertMessage = message;
    },
    openEditTemplate(hook) {
      this.selectedHook = hook;
      this.contentEdit = hook?.settings?.template;
      this.title = hook?.settings?.name_list;
      this.showListModal = true;
    },
    closeEditTemplate() {
      this.showListModal = false;
    },
    openListCatalog(hook) {
      this.selectedHook = hook;
      this.showTemplateModal = true;
    },
    closeTemplateModal() {
      this.showTemplateModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.hook-item {
  word-break: break-word;
  min-width: 140px;
}
.main-container-catalog-modal {
  ::v-deep {
    .modal-container {
      width: 60%;
    }
  }
}
</style>
