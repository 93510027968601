<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top mt-50 bg-primary"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('CRM_MGMT.ADD.TITLE') }}
    </woot-button>

    <div class="row">
      <div class="small-8 columns with-right-space ">
        <p
          v-if="!fetchingList && !kanbanList.length"
          class="no-items-error-message"
        >
          {{ $t('CRM_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="fetchingList && !kanbanList.length"
          :message="$t('CRM_MGMT.LOADING')"
        />

        <table v-if="kanbanList.length" class="woot-table">
          <thead>
            <th
              v-for="(thHeader, i) in $t('CRM_MGMT.LIST.TABLE_HEADER')"
              :key="i"
              :class="i == 3 && 'salesFunnelTh'"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(kanban, index) in kanbanList" :key="index">
              <td>
                {{ kanban.name }}
              </td>
              <td>{{ getColumnNames(kanban.columns) }}</td>
              <td>
                {{
                  kanban.active
                    ? $t('CRM_MGMT.FORM.ACTIVE.ACTIVE')
                    : $t('CRM_MGMT.FORM.ACTIVE.DISABLE')
                }}
              </td>
              <td>
                {{
                  kanban.enable_sales_funnel
                    ? $t('CRM_MGMT.FORM.ACTIVE.ACTIVE')
                    : $t('CRM_MGMT.FORM.ACTIVE.DISABLE')
                }}
              </td>
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('CRM_MGMT.EDIT.BUTTON_TEXT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="edit"
                  @click="openEditPopup(kanban)"
                />
                <woot-button
                  v-tooltip.top="$t('CRM_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[kanban.id]"
                  @click="openDeletePopup(kanban, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('CRM_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-kanban :on-close="hideAddPopup" />
    </woot-modal>

    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-kanban
        v-if="showEditPopup"
        :kanban="selectedKanban"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CRM_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('CRM_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddKanban from './AddKanbanfunnel.vue';
import EditKanban from './EditKanbanfunnel.vue';
import router from '../../..';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  name: 'CrmSettings',
  components: {
    AddKanban,
    EditKanban,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedKanban: {},
      cannedResponseAPI: {
        message: '',
      },
      fetchingList: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      agentList: 'agents/getAgents',
      kanbanList: 'getKanbanfunnels',
      currentAccountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.currentAccountId);
    },
    permissionCrmSettings() {
      if (this.account?.enable_crm === false) {
        return false;
      }

      if (this.currentUser.type_role === 'administrator') {
        return true;
      }
      return this.currentUser?.permissions?.crm_settings;
    },

    deleteConfirmText() {
      return `${this.$t('CRM_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedKanban.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CRM_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedKanban.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedKanban.name}?`;
    },
  },

  watch: {
    account: {
      handler() {
        this.validatePermission();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.validatePermission();
    this.fetchData();
  },

  methods: {
    validatePermission() {
      if (!this.permissionCrmSettings) {
        const path = frontendURL(`accounts/${this.accountId}/conversation`);
        router.push({ path });
      }
    },
    async fetchData() {
      this.fetchingList = true;
      await this.$store.dispatch('fetchKanbanfunnels');
      this.fetchingList = false;
      this.$store.dispatch('agents/get');
    },

    getColumnNames(columns) {
      if (!columns.length) return '';
      return columns.map(column => column.name).join(', ');
    },

    showAlert(message) {
      this.loading[this.selectedKanban.id] = false;
      this.selectedKanban = {};

      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },

    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedKanban = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedKanban = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },

    confirmDeletion() {
      this.loading[this.selectedKanban.id] = true;
      this.closeDeletePopup();
      this.deleteKanban(this.selectedKanban.id);
    },
    deleteKanban(id) {
      this.$store
        .dispatch('deleteKanbanfunnel', id)
        .then(() => {
          this.showAlert(this.$t('CRM_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(error => {
          const errorMessage =
            error?.message || this.$t('CRM_MGMT.DELETE.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>
<style scoped>
.wrap-break-words {
  word-break: break-all;
  white-space: normal;
}

.salesFunnelTh {
  width: 165px;
}
</style>
