<template>
  <div class="medium-12 columns">
    <div class="templates__list-search">
      <fluent-icon icon="search" class="search-icon" size="16" />
      <input ref="search" v-model="query" type="search"
        :placeholder="$t('WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER')" class="templates__search-input" />
    </div>
    <div class="template__list-container">
      <div v-for="(template, i) in filteredTemplateMessages" :key="template.id">
        <div class="button-row">
          <button class="template__list-item" @click="$emit('onSelect', template)">
            <div>
              <div class="flex-between">
                <p class="label-title">{{ template.name }}</p>
                <span class="label-lang label">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.LANGUAGE') }} :
                  {{ template.language }}
                </span>
              </div>
              <div>
                <p class="strong">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.TEMPLATE_BODY') }}
                </p>
                <p class="label-body">{{ getTemplateBody(template) }}</p>
              </div>
              <div class="label-category">
                <p class="strong">{{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.CATEGORY') }}</p>
                <p>{{ template.category }}</p>
              </div>
            </div>
          </button>
          <div v-if="templateIsImage(template) || templateIsDocument(template)" class="media-preview">
            <button @click="onInnerButtonClick(template)">
              <img v-if="templateIsImage(template)" class="image-preview" :src="`${getTemplateImage(template)}`"  />
              <vue-pdf-embed v-if="templateIsDocument(template)" :source="`${getTemplateDocument(template)}`" :width="85"
                :scale="1" :page="1" />
            </button>
            <media-modal :show="mediaPreview" :template="selectedTemplate" @cancel="mediaPreview = false"></media-modal>
            <file-upload class="change-media" :input-id="template.id"
              :accept="templateIsImage(template) ? 'image/png,image/gif,image/jpeg' : 'application/pdf'"
              @input-file="onDirectFileUpload($event, template)">
              <button class="change-button">{{ $t('WHATSAPP_TEMPLATES.PICKER.CHANGE_BUTTON') }}</button>
            </file-upload>
          </div>
        </div>
        <hr v-if="i != filteredTemplateMessages.length - 1" :key="`hr-${i}`" />
      </div>
      <div v-if="!filteredTemplateMessages.length">
        <p>
          {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND') }}
          <strong>{{ query }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import WootButton from '../../../ui/WootButton.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import MediaModal from './MediaModal.vue';
import { DirectUpload } from 'activestorage';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import axios from 'axios';
import FileUpload from 'vue-upload-component';
import whatsappTemplateApi from '../../../../api/whatsappTemplate';

export default {
  mixins: [alertMixin],
  components: {
    WootButton,
    VuePdfEmbed,
    MediaModal,
    FileUpload,
  },
  props: {
    contactId: {
      type: Number,
      default: undefined,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      query: '',
      mediaPreview: false,
      selectedTemplate: null,
      attachedFiles: [],
    };
  },
  mounted() {
    this.$store.dispatch('inboxes/revalidate', { newKey: null });
  },
  watch:{
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      currentChat: 'getSelectedChat',
    }),
    whatsAppTemplateMessages() {
      const templates = this.$store.getters['inboxes/getWhatsAppTemplates'](this.inboxId);
      if (Array.isArray(templates)) {
        return templates.filter(template => template.status.toLowerCase() === 'approved');
      }
      return [];
    },
    filteredTemplateMessages() {
      const lowerCaseQuery = this.query.toLowerCase();
      return this.whatsAppTemplateMessages.filter(template =>
        template.name.toLowerCase().includes(lowerCaseQuery)
      );
    },
  },
  methods: {
    openFileUpload(event) {
      event.stopPropagation();
    },
  updateTemplateList() {
    const templates = this.$store.getters['inboxes/getWhatsAppTemplates'](this.inboxId);
    if (Array.isArray(templates)) {
      this.filteredTemplateMessages = templates.filter(
        template => template.status.toLowerCase() === 'approved'
      );
    }
  },
    onInnerButtonClick(template) {
      event.stopPropagation();
      this.selectedTemplate = template;
      this.mediaPreview = true;
    },
    async onDirectFileUpload(file, template) {
      if (!file) {
        return;
      }
      const upload = new DirectUpload(
        file.file,
        `/api/v1/accounts/${this.accountId}/contacts/${this.contactId}/direct_uploads`,
        {
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.setRequestHeader('api_access_token', this.currentUser.access_token);
          },
        }
      );

      upload.create(async (error, blob) => {
        if (error) {
          this.showAlert(error);
        } else {
          const fileUrl = `${window.location.origin}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
          template.media_url = fileUrl;
          if (this.templateIsImage(template) || this.templateIsDocument(template)) {
            const payload = new FormData();
            payload.append('account_id', this.accountId);
            payload.append('media_url', fileUrl);
            payload.append('media_id', template.media_id ? template.media_id : '');
            payload.append('template_id', template.id);
            payload.append('inbox_id', this.inboxId);
            payload.append('file', file.file);
            const newTemplate = await whatsappTemplateApi.setTemplateMedia(payload);
            template.media_id = newTemplate?.data?.media_id;
            this.$store.dispatch('inboxes/revalidate', { newKey: fileUrl }); 
          }
        }
      });
      
    },
    templateIsImage(template) {
      return template?.components[0]?.format === 'IMAGE';
    },
    templateIsDocument(template) {
      return template?.components[0]?.format === 'DOCUMENT';
    },
    getTemplateBody(template) {
      return template.components.find(component => component.type === 'BODY').text;
    },
    getTemplateImage(template) {
  if (template?.media_url) {
    return `${template.media_url}`;
  }
  this.uploadMetaImageTemplate(template)
  return template?.components[0]?.example?.header_handle[0];
},
    getTemplateDocument(template) {
      if (!template.media_url) {
        this.uploadMetaDocumentTemplate(template)
      }
      return template?.media_url
    },
    async uploadMetaDocumentTemplate(template) {
      axios.get(`${window.location.origin}/api/v1/accounts/${this.accountId}/contacts/${this.contactId}/direct_uploads/get_blob`, {
        params: { url: template?.components[0]?.example?.header_handle[0] },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const buffer = response.data;
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const file = new File([blob], 'arquivo.pdf', { type: 'application/pdf' });
          const upload = new DirectUpload(
            file,
            `/api/v1/accounts/${this.accountId}/contacts/${this.contactId}/direct_uploads`,
            {
              directUploadWillCreateBlobWithXHR: xhr => {
                xhr.setRequestHeader('api_access_token', this.currentUser.access_token);
              },
            }
          );

          upload.create(async (error, blob) => {
            if (error) {
              this.showAlert(error);
            } else {
              const fileUrl = `${window.location.origin}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
              if (this.templateIsImage(template) || this.templateIsDocument(template)) {
                const payload = new FormData();
                payload.append('account_id', this.accountId);
                payload.append('media_url', fileUrl);
                payload.append('media_id', template.media_id ? template.media_id : '');
                payload.append('template_id', template.id);
                payload.append('inbox_id', this.inboxId);
                payload.append('file', file);
                const newTemplate = await whatsappTemplateApi.setTemplateMedia(payload);
                template.media_id = newTemplate?.data?.media_id;
              }
            }
          });
        })
        .catch(error => {
          console.error('Erro ao processar o arquivo:', error);
        });
    },
    async uploadMetaImageTemplate(template) {
  axios.get(`${window.location.origin}/api/v1/accounts/${this.accountId}/contacts/${this.contactId}/direct_uploads/get_blob`, {
    params: { url: template?.components[0]?.example?.header_handle[0] },
    responseType: 'arraybuffer',
  })
    .then(response => {
      const buffer = response.data;
      const blob = new Blob([buffer], { type: 'image/png' });
      const file = new File([blob], 'arquivo.png', { type: 'image/png' });

      const upload = new DirectUpload(
        file,
        `/api/v1/accounts/${this.accountId}/contacts/${this.contactId}/direct_uploads`,
        {
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.setRequestHeader('api_access_token', this.currentUser.access_token);
          },
        }
      );

      upload.create(async (error, blob) => {
        if (error) {
          this.showAlert(error);
        } else {
          const fileUrl = `${window.location.origin}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
          if (this.templateIsImage(template)) {
            const payload = new FormData();
            payload.append('account_id', this.accountId);
            payload.append('media_url', fileUrl);
            payload.append('media_id', template.media_id ? template.media_id : '');
            payload.append('template_id', template.id);
            payload.append('inbox_id', this.inboxId);
            payload.append('file', file);
            const newTemplate = await whatsappTemplateApi.setTemplateMedia(payload);
            template.media_id = newTemplate?.data?.media_id;
          }
        }
      });
    })
    .catch(error => {
      console.error('Erro ao processar o arquivo:', error);
    });
}

  },
};
</script>

<style scoped lang="scss">
.flex-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-one);
}

.templates__list-search {
  align-items: center;
  background-color: var(--s-25);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--s-100);
  display: flex;
  margin-bottom: var(--space-one);
  padding: 0 var(--space-one);

  .search-icon {
    color: var(--s-400);
  }

  .templates__search-input {
    background-color: transparent;
    border: none;
    font-size: var(--font-size-mini);
    height: unset;
    margin: 0;
  }
}

.template__list-container {
  background-color: var(--s-25);
  border-radius: var(--border-radius-medium);
  max-height: 30rem;
  overflow-y: auto;
  padding: var(--space-one);

  .media-preview {
    display: flex;
    flex-direction: column;
    z-index: 3;
  }

  .template__list-item {
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    display: block;
    padding: var(--space-one);
    text-align: left;
    width: 100%;
    z-index: 2;

    &:hover {
      background-color: var(--w-50);
    }

    .label-title {
      font-size: var(--font-size-small);
    }

    .label-category {
      margin-top: var(--space-two);

      span {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bold);
      }
    }

    .label-body {
      font-family: monospace;
    }
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-preview {
  border-radius: 5px 5px 0 0;
  width: 85px;
}

.change-media {
  border-radius: 0 0 5px 5px;
  background-color: #47a6ff;
  width: 85px;
}

.strong {
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-bold);
}

hr {
  border-bottom: 1px solid var(--s-100);
  margin: var(--space-one) auto;
  max-width: 95%;
}
</style>
