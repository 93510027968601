<template>
  <div
    class="conversations-list-wrap"
    :class="{
      hide: !showConversationList,
      'list--full-width': isOnExpandedLayout,
    }"
  >
    <slot />
    <div
      class="chat-list__top"
      :class="{ filter__applied: hasAppliedFiltersOrActiveFolders }"
    >
      <div class="box-inbox">
        <woot-sidemenu-icon />
        <h1
          v-if="inboxes.length < 2"
          class="page-sub-title text-truncate margin-bottom-0"
          :title="pageTitle"
        >
          {{ pageTitle }}
        </h1>

        <!-- select -->
        <select
          v-if="inboxes.length > 1"
          v-model="selectedInbox"
          @change="InboxSelectChange(selectedInbox)"
        >
          <option value="default">
            {{ this.$t('CHAT_LIST.TAB_HEADING2') }}
          </option>
          <option v-for="value in inboxes" :key="value.id" :value="value.id">
            {{ value.name }}
          </option>
        </select>

        <button class="btn-show-modal" @click="showModalNewConversation">
          <img src="~dashboard/assets/images/add-plus.png" alt="add message" />
        </button>
      </div>
    </div>

    <add-custom-views
      v-if="showAddFoldersModal"
      :custom-views-query="foldersQuery"
      :open-last-saved-item="openLastSavedItemInFolder"
      @close="onCloseAddFoldersModal"
    />

    <delete-custom-views
      v-if="showDeleteFoldersModal"
      :show-delete-popup.sync="showDeleteFoldersModal"
      :active-custom-view="activeFolder"
      :custom-views-id="foldersId"
      :open-last-item-after-delete="openLastItemAfterDeleteInFolder"
      @close="onCloseDeleteFoldersModal"
    />

    <div class="filters-box">
      <!-- <pop-over-search
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle-conversation-layout="toggleConversationLayout"
      /> -->
      <chat-type-tabs
        v-if="!hasAppliedFiltersOrActiveFolders"
        :items="assigneeTabItems"
        :active-tab="activeAssigneeTab"
        class="tab--chat-type"
        @chatTabChange="updateAssigneeTab"
      />
      <conversation-basic-filter
        v-if="!hasAppliedFiltersOrActiveFolders"
        @changeFilter="onBasicFilterChange"
      />
    </div>
    <div class="filter--actions">
      <div v-if="hasAppliedFilters && !hasActiveFolders">
        <woot-button
          v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="save"
          @click="onClickOpenAddFoldersModal"
        />
        <woot-button
          v-tooltip.top-end="$t('FILTER.CLEAR_BUTTON_LABEL')"
          size="tiny"
          variant="smooth"
          color-scheme="alert"
          icon="dismiss-circle"
          @click="resetAndFetchData"
        />
      </div>
      <div v-if="hasActiveFolders">
        <woot-button
          v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.EDIT.EDIT_BUTTON')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="edit"
          @click="onToggleAdvanceFiltersModal"
        />
        <woot-button
          v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')"
          size="tiny"
          variant="smooth"
          color-scheme="alert"
          icon="delete"
          @click="onClickOpenDeleteFoldersModal"
        />
      </div>
      <!-- <woot-button v-else v-tooltip.right="$t('FILTER.TOOLTIP_LABEL')" variant="smooth"
          color-scheme="secondary" icon="filter" size="tiny" @click="onToggleAdvanceFiltersModal" /> -->
    </div>

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>
    <conversation-bulk-actions
      v-if="selectedConversations.length"
      :conversations="selectedConversations"
      :all-conversations-selected="allConversationsSelected"
      :selected-inboxes="uniqueInboxes"
      :show-open-action="allSelectedConversationsStatus('open')"
      :show-resolved-action="allSelectedConversationsStatus('resolved')"
      :show-snoozed-action="allSelectedConversationsStatus('snoozed')"
      @select-all-conversations="selectAllConversations"
      @assign-agent="onAssignAgent"
      @update-conversations="onUpdateConversations"
      @assign-labels="onAssignLabels"
      @assign-team="onAssignTeamsForBulk"
    />
    <div
      ref="activeConversation"
      class="conversations-list"
      :class="{ 'is-context-menu-open': isContextMenuOpen }"
    >
      <conversation-card
        v-for="chat in conversationList"
        :key="chat.uuid"
        :active-label="label"
        :team-id="teamId"
        :folders-id="foldersId"
        :chat="chat"
        :conversation-type="conversationType"
        :show-assignee="showAssigneeInConversationCard"
        :selected="isConversationSelected(chat.id)"
        @select-conversation="selectConversation"
        @de-select-conversation="deSelectConversation"
        @assign-agent="onAssignAgent"
        @assign-team="onAssignTeam"
        @assign-label="onAssignLabels"
        @update-conversation-status="toggleConversationStatus"
        @context-menu-toggle="onContextMenuToggle"
        @open-modal="openSnoozeModal"
        @mark-as-unread="markAsUnread"
        @assign-priority="assignPriority"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner" />
      </div>

      <woot-button
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        variant="clear"
        size="expanded"
        class="btn-primary"
        @click="loadMoreConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </woot-button>

      <p
        v-if="showEndOfListMessage"
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>
    <woot-modal
      :show.sync="showAdvancedFilters"
      :on-close="closeAdvanceFiltersModal"
      size="medium"
    >
      <conversation-advanced-filter
        v-if="showAdvancedFilters"
        :initial-filter-types="advancedFilterTypes"
        :initial-applied-filters="appliedFilter"
        :active-folder-name="activeFolderName"
        :on-close="closeAdvanceFiltersModal"
        :is-folder-view="hasActiveFolders"
        @applyFilter="onApplyFilter"
        @updateFolder="onUpdateSavedFilter"
      />
    </woot-modal>

    <woot-modal
      :show.sync="toggleContactsModal"
      :on-close="hideContactsModal"
      size="modal-xl"
    >
      <div class="column content-box">
        <div class="row">
          <contacts-view @toggle-contacts-view="onToggleContactsModal" />
        </div>
      </div>
    </woot-modal>
    <woot-snoozed-modal
      v-if="showSnoozedModal"
      :show="showSnoozedModal"
      :scheduled-at="scheduledAt"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import languages from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';
import wootConstants from 'dashboard/constants/globals';
import { generateValuesForEditCustomViews } from 'dashboard/helper/customViewsHelper';
import adminMixin from 'dashboard/mixins/isAdmin';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews.vue';
import countries from 'shared/constants/countries';
import alertMixin from 'shared/mixins/alertMixin';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import filterMixin from 'shared/mixins/filterMixin';
import WootSnoozedModal from '../components/widgets/conversation/WootSnoozedModal.vue';
import filterQueryGenerator from '../helper/filterQueryGenerator.js';
import conversationMixin from '../mixins/conversations';
import timeMixin from '../mixins/time';
import ChatTypeTabs from './widgets/ChatTypeTabs';
import advancedFilterTypes from './widgets/conversation/advancedFilterItems';
import ConversationAdvancedFilter from './widgets/conversation/ConversationAdvancedFilter';
import ConversationBasicFilter from './widgets/conversation/ConversationBasicFilter';
import ConversationBulkActions from './widgets/conversation/conversationBulkActions/Index.vue';
import ConversationCard from './widgets/conversation/ConversationCard';

import agentMixin from 'dashboard/mixins/agentMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import {
  hasPressedAltAndJKey,
  hasPressedAltAndKKey,
} from 'shared/helpers/KeyboardHelpers';
import { CONVERSATION_EVENTS } from '../helper/AnalyticsHelper/events';
import { closeConversation, conversationListPageURL, frontendURL } from '../helper/URLHelper';
import router from '../routes';
import ContactsView from '../routes/dashboard/contacts/components/ContactsView.vue';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../store/modules/conversations/helpers/actionHelpers';
export default {
  components: {
    AddCustomViews,
    ChatTypeTabs,
    ConversationCard,
    ConversationAdvancedFilter,
    DeleteCustomViews,
    WootSnoozedModal,
    ConversationBulkActions,
    ConversationBasicFilter,
    ContactsView,
  },
  mixins: [
    timeMixin,
    conversationMixin,
    eventListenerMixins,
    alertMixin,
    filterMixin,
    adminMixin,
    uiSettingsMixin,
    agentMixin,
  ],
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showConversationList: {
      default: true,
      type: Boolean,
    },
    isOnExpandedLayout: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showSnoozedModal: false,
      scheduledAt: false,
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      activeSortBy: wootConstants.SORT_BY_TYPE.LATEST,
      showAdvancedFilters: false,
      advancedFilterTypes: advancedFilterTypes.map(filter => ({
        ...filter,
        attributeName: this.$t(`FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
      })),
      // chatsOnView is to store the chats that are currently visible on the screen,
      // which mirrors the conversationList.
      chatsOnView: [],
      foldersQuery: {},
      showAddFoldersModal: false,
      showDeleteFoldersModal: false,
      selectedConversations: [],
      selectedInboxes: [],
      isContextMenuOpen: false,
      appliedFilter: [],
      selectedInbox: 'default',
      toggleContactsModal: false,
      countChatsAll: null,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      chatLists: 'getAllConversations',
      accountId: 'getCurrentAccountId',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      appliedFilters: 'getAppliedConversationFilters',
      folders: 'customViews/getCustomViews',
      inboxes: 'inboxes/getInboxes',
      agentList: 'agents/getAgents',
      teamsList: 'teams/getTeams',
      inboxesList: 'inboxes/getInboxes',
      campaigns: 'campaigns/getAllCampaigns',
      labels: 'labels/getLabels',
      agentContact: 'contacts/getContactByAgentId',
      statusFilterConversationAndContact: 'getStatusFilter',
    }),
    hasAppliedFilters() {
      return this.appliedFilters.length !== 0;
    },
    hasActiveFolders() {
      return this.activeFolder && this.foldersId !== 0;
    },
    hasAppliedFiltersOrActiveFolders() {
      return this.hasAppliedFilters || this.hasActiveFolders;
    },
    savedFoldersValue() {
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      return {};
    },
    showEndOfListMessage() {
      return (
        this.conversationList.length &&
        this.hasCurrentPageEndReached &&
        !this.chatListLoading
      );
    },
    currentUserDetails() {
      const { id, name } = this.currentUser;
      return {
        id,
        name,
      };
    },
    assigneeTabItems() {
      const ASSIGNEE_TYPE_TAB_KEYS = this.assigneeTabItemsFilter();
      let tabKey = {
        me: 'mineCount',
      };

      if (this.inbox.channel_type !== 'Channel::Internal') {
        tabKey.unassigned = 'unAssignedCount';
      }
      if (
        this.inbox.enable_view_assigned_conversations ||
        this.currentUser.permissions.see_all_conversations ||
        this.conversationType === 'mention' ||
        this.currentUser?.permissions?.team_chat_only ||
        (this.currentUser?.permissions?.see_conversations_team &&
          this.inbox.channel_type !== 'Channel::Internal')
      ) {
        tabKey.all = 'allCount';
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(ASSIGNEE_TYPE_TAB_KEYS)) {
        if (key === this.inbox.id) {
          tabKey = value;
        }
      }
      return Object.keys(tabKey).map((key, i) => {
        let count = this.conversationStats[tabKey[key]] || 0;

        if (i === 2 && this.countChatsAll) {
          count = this.countChatsAll;
        }

        return {
          key,
          name: this.$t(`CHAT_LIST.ASSIGNEE_TYPE_TABS.${key}`),
          count,
        };
      });
    },
    showAssigneeInConversationCard() {
      return (
        this.hasAppliedFiltersOrActiveFolders ||
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ALL
      );
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.activeAssigneeTab
      );
    },
    currentPageFilterKey() {
      return this.hasAppliedFiltersOrActiveFolders
        ? 'appliedFilters'
        : this.activeAssigneeTab;
    },
    currentFiltersPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.currentPageFilterKey
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.currentPageFilterKey
      );
    },
    activeAssigneeTabCount() {
      const { activeAssigneeTab } = this;
      const count = this.assigneeTabItems?.find(
        item => item?.key === activeAssigneeTab
      ).count;
      return count;
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox ? this.conversationInbox : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        sortBy: this.activeSortBy,
        contact_id: this.agentContact(this.currentUser.id)?.id,
        page: this.conversationListPagination,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId || undefined,
        conversationType: this.conversationType || undefined,
        // folders: this.hasActiveFolders ? this.savedFoldersValue : undefined,
      };
    },
    conversationListPagination() {
      const conversationsPerPage = 25;
      const isNoFiltersOrFoldersAndChatListNotEmpty =
        !this.hasAppliedFiltersOrActiveFolders && this.chatsOnView !== [];
      const isUnderPerPage =
        this.chatsOnView.length < conversationsPerPage &&
        this.activeAssigneeTabCount < conversationsPerPage &&
        this.activeAssigneeTabCount > this.chatsOnView.length;

      if (isNoFiltersOrFoldersAndChatListNotEmpty && isUnderPerPage) {
        return 1;
      }
      return this.currentPage + 1;
    },
    pageTitle() {
      // if(this.inboxes.length > 1 && !this.inbox.name){
      //   return ;
      // }

      if (this.hasAppliedFilters) {
        if (this.inboxes.length < 2) {
          return this.$t('CHAT_LIST.TAB_HEADING');
        }
        return this.$t('CHAT_LIST.TAB_HEADING2');
      }
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      if (this.conversationType === 'mention') {
        return this.$t('CHAT_LIST.MENTION_HEADING');
      }
      if (this.conversationType === 'participating') {
        return this.$t('CONVERSATION_PARTICIPANTS.SIDEBAR_MENU_TITLE');
      }
      if (this.conversationType === 'unattended') {
        return this.$t('CHAT_LIST.UNATTENDED_HEADING');
      }
      if (this.hasActiveFolders) {
        return this.activeFolder.name;
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    contactConversations() {
      const currentId = this.currentUser?.id;
      const currentConversations = this.$store.getters[
        'contactConversations/getContactConversation'
      ](
        this.currentChat?.contact_id,
        currentId,
        this.isLimitedAgent,
        this.currentUser.permissions.see_conversations_team &&
          this.inbox.channel_type !== 'Channel::Internal'
      );
      return currentConversations;
    },
    conversationList() {
      let conversationList = [];
      const teamsMap = this.teamsList.reduce((map, team) => {
        map[team.id] = team;
        return map;
      }, {});

      const filters = this.conversationFilters;
      if (!this.hasAppliedFiltersOrActiveFolders) {
        if (this.activeAssigneeTab === 'me') {
          conversationList = [...this.mineChatsList(filters)];
        } else if (this.activeAssigneeTab === 'unassigned') {
          conversationList = [
            ...this.unAssignedChatsList(
              filters,
              this.currentUser.permissions.see_conversations_team &&
                this.inbox.channel_type !== 'Channel::Internal',
              this.isAgent
            ),
          ];
        } else {
          conversationList = [
            ...this.allChatList(
              filters,
              this.currentUser.permissions.see_conversations_team &&
                this.inbox.channel_type !== 'Channel::Internal',
              this.currentUserID
            ),
          ];
        }
      } else {
        conversationList = [...this.chatLists];
      }
      let listAllChats = [];
      listAllChats = this.allChatList(
        filters,
        this.currentUser.permissions.see_conversations_team &&
          this.inbox.channel_type !== 'Channel::Internal',
        this.currentUserID
      );

      if (
        this.currentUser.type_role !== 'administrator' &&
        this.currentUser.permissions.team_chat_only &&
        this.activeAssigneeTab === 'all'
      ) {
        const listByTeam = listAllChats.filter(chat => {
          return (
            this.getMsgFromTeam(chat?.meta?.team?.id) || !chat.meta.assignee
          );
        });

        if (this.currentUser.permissions.team_chat_only) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.countChatsAll = listByTeam.length;
        }

        conversationList = listByTeam;
      }
      if (
        this.currentUser.type_role !== 'administrator' &&
        this.currentUser.permissions.see_conversations_team &&
        this.inbox.channel_type !== 'Channel::Internal'
      ) {
        let filterConversationTeam = conversationList.filter(conversation => {
          const currentIDTeamAssignConversation = conversation?.meta?.team?.id;
          const assigneeID = conversation?.meta?.assignee?.id;
          const isPartOfMyTeam =
            teamsMap[currentIDTeamAssignConversation]?.is_member;

          switch (true) {
            case assigneeID === this.currentUserID:
              return true;
            case !assigneeID && isPartOfMyTeam === undefined:
              return true;
            case isPartOfMyTeam:
              return true;
            default:
              return false;
          }
        });
        return filterConversationTeam;
      }

      return conversationList;
    },
    activeFolder() {
      if (this.foldersId) {
        const activeView = this.folders.filter(
          view => view.id === Number(this.foldersId)
        );
        const [firstValue] = activeView;
        return firstValue;
      }
      return undefined;
    },
    activeFolderName() {
      return this.activeFolder?.name;
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    allConversationsSelected() {
      return (
        this.conversationList.length === this.selectedConversations.length &&
        this.conversationList.every(el =>
          this.selectedConversations.includes(el.id)
        )
      );
    },
    uniqueInboxes() {
      return [...new Set(this.selectedInboxes)];
    },
  },
  watch: {
    activeAssigneeTab(newVal) {
      if (newVal === 'unassigned') {
        const filters = this.conversationFilters;
        this.conversationList = [
          ...this.unAssignedChatsList(
            filters,
            this.currentUser.permissions.see_conversations_team &&
              this.inbox.channel_type !== 'Channel::Internal',
            this.isAgent
          ),
        ];
      }
      if (newVal === 'all') {
        const filters = this.conversationFilters;
        this.conversationList = [
          ...this.allChatList(
            filters,
            this.currentUser.permissions.see_conversations_team &&
              this.inbox.channel_type !== 'Channel::Internal',
            this.currentUserID
          ),
        ];
      }
    },
    activeInbox: {
      handler(activeInbox) {
        if (!activeInbox) {
          this.selectedInbox = 'default';
        } else {
          this.selectedInbox = activeInbox;
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.hideContactsModal();
      },
      immediate: true,
    },
    statusFilterConversationAndContact: {
      handler(newValue) {
        if (newValue === 1) {
          this.onToggleAdvanceFiltersModal();
        }
      },
      immediate: true,
    },
    activeTeam() {
      this.resetAndFetchData();
    },
    conversationInbox() {
      this.resetAndFetchData();
    },
    label() {
      this.resetAndFetchData();
    },
    conversationType() {
      this.resetAndFetchData();
    },
    activeFolder() {
      this.resetAndFetchData();
    },
    chatLists() {
      this.chatsOnView = this.conversationList;
    },
  },
  mounted() {
    this.$store.dispatch('setChatStatusFilter', this.activeStatus);
    this.$store.dispatch('setChatSortFilter', this.activeSortBy);
    this.resetAndFetchData();

    if (this.hasActiveFolders) {
      this.$store.dispatch('campaigns/get');
    }

    bus.$on('fetch_conversation_stats', () => {
      this.$store.dispatch('conversationStats/get', this.conversationFilters);
    });

    document.addEventListener('click', this.handleGlobalClick);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleGlobalClick);
  },

  methods: {
    handleGlobalClick() {
      this.$store.dispatch('conversationStats/get', this.conversationFilters);
    },
    onToggleContactsModal() {
      this.toggleContactsModal = !this.toggleContactsModal;
    },
    showModalNewConversation() {
      this.toggleContactsModal = true;
    },
    hideContactsModal() {
      this.toggleContactsModal = false;
    },
    frontendURL,
    InboxSelectChange(id) {
      if (id !== 'default') {
        const path = frontendURL(`accounts/${this.accountId}/inbox/${id}`);
        router.push({ path });
        return;
      }

      const path = frontendURL(`accounts/${this.accountId}/conversation`);
      router.push({ path });
    },
    toggleConversationLayout() {
      const { LAYOUT_TYPES } = wootConstants;
      const {
        conversation_display_type: conversationDisplayType = LAYOUT_TYPES.CONDENSED,
      } = this.uiSettings;
      const newViewType =
        conversationDisplayType === LAYOUT_TYPES.CONDENSED
          ? LAYOUT_TYPES.EXPANDED
          : LAYOUT_TYPES.CONDENSED;
      this.updateUISettings({
        conversation_display_type: newViewType,
        previously_used_conversation_display_type: newViewType,
      });
    },
    assigneeTabItemsFilter() {
      let assigneeTypeTabKeys = {};

      this.inboxes.forEach(inbox => {
        if (inbox.channel_type !== 'Channel::Internal') {
          assigneeTypeTabKeys[inbox.id] = {
            me: 'mineCount',
            unassigned: 'unAssignedCount',
          };
        }
        if (
          (this.currentUser.permissions.see_conversations_team &&
            inbox.channel_type !== 'Channel::Internal') ||
          this.isPermissionAllConversations
        ) {
          assigneeTypeTabKeys[inbox.id] = {
            ...assigneeTypeTabKeys[inbox.id],
            all: 'allCount',
          };
        }
        if (inbox.enable_view_assigned_conversations || this.isAdmin) {
          assigneeTypeTabKeys[inbox.id] = {
            ...assigneeTypeTabKeys[inbox.id],
            all: 'allCount',
          };
        }
      });

      return assigneeTypeTabKeys;
    },
    onApplyFilter(payload) {
      this.resetBulkActions();
      this.foldersQuery = filterQueryGenerator(payload);
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchFilteredConversations(payload);
      this.setStatusFilter(0);
    },
    setStatusFilter(status) {
      this.$store.dispatch('setStatusFilterConversationEndContact', { status });
    },
    onUpdateSavedFilter(payload, folderName) {
      const payloadData = {
        ...this.activeFolder,
        name: folderName,
        query: filterQueryGenerator(payload),
      };
      this.$store.dispatch('customViews/update', payloadData);
      this.closeAdvanceFiltersModal();
    },
    onClickOpenAddFoldersModal() {
      this.showAddFoldersModal = true;
    },
    onCloseAddFoldersModal() {
      this.showAddFoldersModal = false;
    },
    onClickOpenDeleteFoldersModal() {
      this.showDeleteFoldersModal = true;
    },
    onCloseDeleteFoldersModal() {
      this.showDeleteFoldersModal = false;
    },
    onToggleAdvanceFiltersModal() {
      if (!this.hasAppliedFilters && !this.hasActiveFolders) {
        this.initializeExistingFilterToModal();
      }
      if (this.hasActiveFolders) {
        this.initializeFolderToFilterModal(this.activeFolder);
      }
      this.showAdvancedFilters = true;
    },
    closeAdvanceFiltersModal() {
      this.showAdvancedFilters = false;
      this.appliedFilter = [];
      this.setStatusFilter(0);
    },
    setParamsForEditFolderModal() {
      // Here we are setting the params for edit folder modal to show the existing values.

      // For agent, team, inboxes,and campaigns we get only the id's from the query.
      // So we are mapping the id's to the actual values.

      // For labels we get the name of the label from the query.
      // If we delete the label from the label list then we will not be able to show the label name.

      // For custom attributes we get only attribute key.
      // So we are mapping it to find the input type of the attribute to show in the edit folder modal.
      const params = {
        agents: this.agentList,
        teams: this.teamsList,
        inboxes: this.inboxesList,
        labels: this.labels,
        campaigns: this.campaigns,
        languages: languages,
        countries: countries,
        filterTypes: advancedFilterTypes,
        allCustomAttributes: this.$store.getters[
          'attributes/getAttributesByModel'
        ]('conversation_attribute'),
      };
      return params;
    },
    initializeFolderToFilterModal(activeFolder) {
      // Here we are setting the params for edit folder modal.
      //  To show the existing values. when we click on edit folder button.

      // Here we get the query from the active folder.
      // And we are mapping the query to the actual values.
      // To show in the edit folder modal by the help of generateValuesForEditCustomViews helper.
      const query = activeFolder?.query?.payload;
      if (!Array.isArray(query)) return;

      this.appliedFilter.push(
        ...query.map(filter => ({
          attribute_key: filter.attribute_key,
          attribute_model: filter.attribute_model,
          filter_operator: filter.filter_operator,
          values: Array.isArray(filter.values)
            ? generateValuesForEditCustomViews(
                filter,
                this.setParamsForEditFolderModal()
              )
            : [],
          query_operator: filter.query_operator,
          custom_attribute_type: filter.custom_attribute_type,
        }))
      );
    },
    getKeyboardListenerParams() {
      const allConversations = this.$refs.activeConversation.querySelectorAll(
        'div.conversations-list div.conversation'
      );
      const activeConversation = this.$refs.activeConversation.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;
      return {
        allConversations,
        activeConversation,
        activeConversationIndex,
        lastConversationIndex,
      };
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndJKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[0].click();
        }
        if (activeConversationIndex >= 1) {
          allConversations[activeConversationIndex - 1].click();
        }
      }
      if (hasPressedAltAndKKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
          lastConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[lastConversationIndex].click();
        } else if (activeConversationIndex < lastConversationIndex) {
          allConversations[activeConversationIndex + 1].click();
        }
      }
    },
    resetAndFetchData() {
      this.appliedFilter = [];
      this.resetBulkActions();
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('clearConversationFilters');
      this.setStatusFilter(0);
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.foldersId) {
        return;
      }
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => this.$emit('conversation-load'));
    },
    loadMoreConversations() {
      if (!this.hasAppliedFiltersOrActiveFolders) {
        this.fetchConversations();
      }
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.hasAppliedFilters) {
        this.fetchFilteredConversations(this.appliedFilters);
      }
    },
    fetchFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: filterQueryGenerator(payload),
          page,
        })
        .then(() => this.$emit('conversation-load'));
      this.showAdvancedFilters = false;
    },
    fetchSavedFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: payload,
          page,
        })
        .then(() => this.$emit('conversation-load'));
    },
    updateAssigneeTab(selectedTab) {
      if (this.activeAssigneeTab !== selectedTab) {
        this.resetBulkActions();
        bus.$emit('clearSearchInput');
        this.activeAssigneeTab = selectedTab;
        if (!this.currentPage) {
          this.fetchConversations();
        }
      }
    },
    resetBulkActions() {
      this.selectedConversations = [];
      this.selectedInboxes = [];
    },
    onBasicFilterChange(value, type) {
      if (type === 'status') {
        this.activeStatus = value;
      } else {
        this.activeSortBy = value;
      }
      this.resetAndFetchData();
    },
    openLastSavedItemInFolder() {
      const lastItemOfFolder = this.folders[this.folders.length - 1];
      const lastItemId = lastItemOfFolder.id;
      this.$router.push({
        name: 'folder_conversations',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInFolder() {
      if (this.folders.length > 0) {
        this.openLastSavedItemInFolder();
      } else {
        this.$router.push({ name: 'home' });
        this.fetchConversations();
      }
    },
    isConversationSelected(id) {
      return this.selectedConversations.includes(id);
    },
    selectConversation(conversationId, inboxId) {
      this.selectedConversations.push(conversationId);
      this.selectedInboxes.push(inboxId);
    },
    deSelectConversation(conversationId, inboxId) {
      this.selectedConversations = this.selectedConversations.filter(
        item => item !== conversationId
      );
      this.selectedInboxes = this.selectedInboxes.filter(
        item => item !== inboxId
      );
    },
    selectAllConversations(check) {
      if (check) {
        this.selectedConversations = this.conversationList.map(item => item.id);
        this.selectedInboxes = this.conversationList.map(item => item.inbox_id);
      } else {
        this.resetBulkActions();
      }
    },
    // Same method used in context menu, conversationId being passed from there.
    async onAssignAgent(agent, conversationId = null) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: conversationId || this.selectedConversations,
          fields: {
            assignee_id: agent.id,
          },
        });
        this.selectedConversations = [];
        if (conversationId) {
          this.showAlert(
            this.$t(
              'CONVERSATION.CARD_CONTEXT_MENU.API.AGENT_ASSIGNMENT.SUCCESFUL',
              {
                agentName: agent.name,
                conversationId,
              }
            )
          );

          closeConversation(
            this.accountId,
            router,
            this.currentUser?.id,
            agent?.id
          );
        } else {
          this.showAlert(this.$t('BULK_ACTION.ASSIGN_SUCCESFUL'));
        }
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.ASSIGN_FAILED'));
      }
    },
    async assignPriority(priority, conversationId = null) {
      this.$store.dispatch('setCurrentChatPriority', {
        priority,
        conversationId,
      });
      this.$store
        .dispatch('assignPriority', { conversationId, priority })
        .then(() => {
          this.$track(CONVERSATION_EVENTS.CHANGE_PRIORITY, {
            newValue: priority,
            from: 'Context menu',
          });
          this.showAlert(
            this.$t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.SUCCESSFUL', {
              priority,
              conversationId,
            })
          );
        });
    },
    async markAsUnread(conversationId) {
      try {
        await this.$store.dispatch('markMessagesUnread', {
          id: conversationId,
        });
        const {
          params: { accountId, inbox_id: inboxId, label, teamId },
          name,
        } = this.$route;
        let conversationType = '';
        if (isOnMentionsView({ route: { name } })) {
          conversationType = 'mention';
        } else if (isOnUnattendedView({ route: { name } })) {
          conversationType = 'unattended';
        }
        this.$router.push(
          conversationListPageURL({
            accountId,
            conversationType: conversationType,
            customViewId: this.foldersId,
            inboxId,
            label,
            teamId,
          })
        );
      } catch (error) {
        // Ignore error
      }
    },
    async onAssignTeam(team, conversationId = null) {
      try {
        await this.$store.dispatch('assignTeam', {
          conversationId,
          teamId: team.id,
        });
        this.showAlert(
          this.$t(
            'CONVERSATION.CARD_CONTEXT_MENU.API.TEAM_ASSIGNMENT.SUCCESFUL',
            {
              team: team.name,
              conversationId,
            }
          )
        );
      } catch (error) {
        this.showAlert(
          this.$t('CONVERSATION.CARD_CONTEXT_MENU.API.TEAM_ASSIGNMENT.FAILED')
        );
      }
    },
    // Same method used in context menu, conversationId being passed from there.
    async onAssignLabels(labels, conversationId = null) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: conversationId || this.selectedConversations,
          labels: {
            add: labels,
          },
        });
        this.selectedConversations = [];
        if (conversationId) {
          this.showAlert(
            this.$t(
              'CONVERSATION.CARD_CONTEXT_MENU.API.LABEL_ASSIGNMENT.SUCCESFUL',
              {
                labelName: labels[0],
                conversationId,
              }
            )
          );
        } else {
          this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_SUCCESFUL'));
        }
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_FAILED'));
      }
    },
    async onAssignTeamsForBulk(team) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            team_id: team.id,
          },
        });
        this.selectedConversations = [];
        this.showAlert(this.$t('BULK_ACTION.TEAMS.ASSIGN_SUCCESFUL'));
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.TEAMS.ASSIGN_FAILED'));
      }
    },
    closeModal() {
      this.showSnoozedModal = false;
    },
    openSnoozeModal(value) {
      this.showSnoozedModal = value;
    },
    async onUpdateConversations(status) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            status,
          },
        });
        this.selectedConversations = [];
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_SUCCESFUL'));
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_FAILED'));
      }
    },
    toggleConversationStatus(conversationId, status, snoozedUntil) {
      const validateStatus = {
        activeInbox: this.activeInbox,
        contactConversations: this.contactConversations,
        currentId: this.currentUser?.id,
        contactId: this.currentChat?.contact_id,
        messageSaved: this.$t('CONVERSATION.CHANGE_STATUS'),
        messageOpen: this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION'),
        conversation: this.currentChat,
      };
      this.$store
        .dispatch('toggleStatus', {
          conversationId,
          status,
          snoozedUntil,
          validateStatus,
        })
        .then(() => {
          if (status === 'resolved') {
            closeConversation(
              this.accountId,
              router,
              0,
              null,
              this.activeInbox
            );
          }
          this.isLoading = false;
        });
    },
    allSelectedConversationsStatus(status) {
      if (!this.selectedConversations.length) return false;
      return this.selectedConversations.every(item => {
        return this.$store.getters.getConversationById(item).status === status;
      });
    },
    onContextMenuToggle(state) {
      this.isContextMenuOpen = state;
    },
    getMsgFromTeam(teamId) {
      return this.teamsList?.some(team => team.id === teamId && team.is_member);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';

.filters-box {
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: space-around;
}

.btn-show-modal {
  padding: 5px;
  border-radius: 5px;
  background: var(--g-20);
}

.btn-show-modal img {
  height: 15px;
  cursor: pointer;
}

.box-inbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-list__top {
  flex-direction: column;
}

.box-inbox select {
  max-width: 80%;
  margin: 0;
  border: none;
}

.box-inbox select:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.conversations-list {
  // Prevent the list from scrolling if the submenu is opened
  &.is-context-menu-open {
    overflow: hidden !important;
  }
}

.conversations-list-wrap {
  flex-shrink: 0;
  margin-top: 10px;
  flex-basis: clamp(32rem, 4vw + 34rem, 44rem);
  overflow: hidden;

  &.hide {
    display: none;
  }

  &.list--full-width {
    flex-basis: 100%;
  }

  .page-sub-title {
    font-size: var(--font-size-two);
  }
}

.filter--actions {
  display: flex;
  margin: 0 var(--space-normal);
  width: 100%;
  gap: var(--space-smaller);
}

.filter__applied {
  padding-bottom: var(--space-slab) !important;
  border-bottom: 1px solid var(--color-border);
}

.tab--chat-type {
  padding: 0 var(--space-normal);

  ::v-deep {
    .tabs {
      padding: 0;
    }
  }
}

.conversation--status-pill {
  background: var(--color-background);
  border-radius: var(--border-radius-small);
  color: var(--color-medium-gray);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-medium);
  margin: var(--space-micro) var(--space-small) 0;
  padding: var(--space-smaller);
  text-transform: capitalize;
}

.chat-list__title {
  max-width: 85%;
}
</style>
