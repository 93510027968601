import IntegrationsAPI from '../../../api/integrations';
export const actions = {
  fetchCatalogProducts: async ({ commit }, { page, hookId = null, name = null }) => {
    commit('SET_PRODUCT_UI_FLAG', { isFetching: true });
    try {
      if (name && name.length > 1 && page === 1) {
        commit('CLEAR_PRODUCTS');
      }
      const { data: { catalog_product_bases, total_count } } = await IntegrationsAPI.getCatalogProductBases(page, hookId, name);
      if (catalog_product_bases && catalog_product_bases.length) {
        commit('SET_PRODUCTS', catalog_product_bases);
      }
      commit('SET_PRODUCT_META', {
        current_page: page,
        total_pages: total_count,
      });
      return catalog_product_bases
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      commit('SET_PRODUCT_UI_FLAG', { isFetching: false });
    }
  },
};