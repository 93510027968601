<template>
  <woot-modal :show="showCatalogModal" :on-close="onClose">
    <div v-if="!localUploadInProgress">
      <woot-modal-header :header-title="$t('INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.TITLE')
        " :header-content="$t('INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.DESCRIPTION')
          " />
      <form class="row" @submit.prevent="handleSubmitFile">
        <div class="medium-12 columns">
          <label :class="{ error: $v.fileTitle.$error }">
            {{
              $t(
                'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.FIELD_TITLE'
              )
            }}
            <input v-model.trim="fileTitle" type="text" :placeholder="$t(
              'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.PLACEHOLDER_TITLE'
            )
              " @input="$v.fileTitle.$touch" />
          </label>
        </div>
        <div class="medium-12 columns">
          <label>
            {{
              $t(
                'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.FIELD_PRODUCT_NAME'
              )
            }}
            <select :class="renderStyleNameProduct" v-model="nameProduct" @change="handleChangeNameProduct(nameProduct)">
              <option class="default-select" disabled value="">
                {{
                  $t(
                    'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.PLACEHOLDER_PRODUCT_NAME'
                  )
                }}
              </option>
              <option v-for="value in columnsName" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </label>
        </div>

        <div class="medium-12 columns">
          <label>
            {{
              $t(
                'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.FIELD_PRODUCT_LINK'
              )
            }}
            <select :class="renderStyleLinkProduct" v-model="linkProduct" @change="handleChangeNameProduct(linkProduct)">
              <option disabled value="">
                {{
                  $t(
                    'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.PLACEHOLDER_PRODUCT_LINK'
                  )
                }}
              </option>
              <option v-for="value in columnsName" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </label>
        </div>

        <div class="medium-12 columns">
          <label>
            {{
              $t(
                'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.FIELD_PRODUCT_IMAGE'
              )
            }}
            <select :class="renderStyleImageProduct" v-model="imageProduct" @change="handleChangeNameProduct(imageProduct)">
              <option disabled value="">
                {{
                  $t(
                    'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.PLACEHOLDER_PRODUCT_IMAGE'
                  )
                }}
              </option>
              <option v-for="value in columnsName" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </label>
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-button :is-disabled="!canSubmit">
              {{
                $t(
                  'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.BUTTON_CREATE'
                )
              }}
            </woot-button>
            <woot-button variant="clear" @click.prevent="onClose">
              {{
                $t(
                  'INTEGRATION_APPS.MODAL_CATALOG_SELECT_COLUMN_NAME.BUTTON_CANCEL'
                )
              }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="localUploadInProgress" :class="{
      'container-icons': true,
      'error-not-space': typeFile === 'not_espace',
    }">
      <img v-if="actionContenIcon" :src="renderSvgAction" />
      <woot-modal-header :header-content="renderMessageAction" />
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import svgError from '../../../../../../../../public/integrations/catalog/error-close-svgrepo-com.svg';
import svgSucess from '../../../../../../../../public/integrations/catalog/check-svgrepo-com.svg';
import { mapGetters } from 'vuex';
export default {
  props: {
    showCatalogModal: {
      type: Boolean,
      default: true,
    },
    isUploadInProgress: {
      type: Boolean,
      default: false,
    },
    isErrorLoadingFile: {
      type: Boolean,
      default: false,
    },
    columnsName: {
      type: Array,
      default: [],
    },
    contentOfUploadedFile: {
      type: Array,
      default: [],
    },
    integration: {
      type: Object,
      default: () => ({}),
    },
    fileName: {
      type: String,
      default: '',
    },
    typeFile: {
      type: String,
      default: 'xlsx',
    },
  },
  data() {
    return {
      localUploadInProgress: this.isUploadInProgress,
      fileTitle: null,
      nameProduct: '',
      linkProduct: '',
      imageProduct: '',
      inbox_id: 1,
    };
  },
  validations: {
    fileTitle: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      uiFlags: 'integrations/getUIFlags',
      websiteInboxes: 'inboxes/getWebsiteInboxes',
      dialogFlowEnabledInboxes: 'inboxes/dialogFlowEnabledInboxes',
    }),
    renderStyleNameProduct(){
      return  this.nameProduct === '' ? 'selectedCurrent' : 'defaultClass';
    },
    renderStyleLinkProduct(){
      return this.linkProduct  === '' ? 'selectedCurrent' : 'defaultClass';
    },
    renderStyleImageProduct(){
      return this.imageProduct  === '' ? 'selectedCurrent' : 'defaultClass';
    },
    canSubmit() {
      return this.fileTitle && this.nameProduct && this.linkProduct;
    },
    formItems() {
      return this.integration.settings_form_schema;
    },
    renderSvgAction() {
      return !this.isErrorLoadingFile ? svgSucess : svgError;
    },
    renderMessageAction() {
      if (this.isErrorLoadingFile) {
        if (this.typeFile === 'xlsx') {
          return this.$t(
            'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.ERROR_LOAD_FILE_XLSX'
          );
        }
        if (this.typeFile === 'xml') {
          return this.$t(
            'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.ERROR_LOAD_FILE_XML'
          );
        }
        if (this.typeFile === 'not_espace') {
          return this.$t(
            'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.ERROR_SIZE_FILE'
          );
        }
      }

      return this.$t(
        'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.SUCCESS_LOAD_FILE'
      );
    },
    actionContenIcon() {
      return this.typeFile === 'xml' || this.typeFile === 'xlsx';
    },
  },
  watch: {
    isUploadInProgress(newVal) {
      this.localUploadInProgress = newVal;
    },
  },
  methods: {
    mapDynamically(data, mapping, id) {
    const mappingEntries = Object.entries(mapping);

    return data
      .map(item => {
        if (!item || Object.keys(item).length === 0) return null;

        let result = {};

        for (const [key, header] of mappingEntries) {
          if (item.hasOwnProperty(header)) {
            result[key] = typeof item[header] === 'object' 
              ? JSON.stringify(item[header]) 
              : item[header];
          }
        }

        result['interationId'] = id;

        return result;
      }).filter(item => item !== null);
    },
    async handleSubmitFile() {
      const nameColumns = {
        title_catalog: this.fileTitle,
        name_product: this.nameProduct,
        link_product: this.linkProduct,
        url_image: this.imageProduct,
      };


      const hookPayload = {
        app_id: 'catalog',
        inbox_id: this.inbox_id,
        settings: {
          catalog_id: this.fileName,
          name_list: this.fileTitle,
          percentageLoading: 'Carregando 0%',
          template: this.$t('INTEGRATION_APPS.TEMPLATE.DEFAULT_CONTENT'),
        },
      };
      try {
        const responseHook = await this.$store.dispatch(
          'integrations/createHook',
          hookPayload
        );
        this.localUploadInProgress = true;
        const payloadCatalog = {
          catalog_product_bases: this.mapDynamically(
            this.contentOfUploadedFile,
            nameColumns,
            responseHook?.id
          ),
          integrationId: responseHook?.id,
        };
        await await this.$store.dispatch(
          'integrations/createCatalogProductBase',
          payloadCatalog
        );

      } catch (error) {
        console.log(error)
      } 
      return true;
    },
    handleChangeNameProduct() {
      return '';
    },
    resetState() {
      this.fileTitle = '';
      this.nameProduct = '';
      this.linkProduct = '';
      this.imageProduct = '';
      this.inbox_id = 1;
    },
    onClose() {
      this.resetState();
      this.$emit('on-close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.8rem 0;
  width: 100%;
  flex-direction: column;
}

.container-icons {
  font-weight: 600;

  img {
    display: flex;
    width: 15%;
    height: 15%;
    margin: 56px auto 0;
  }

  margin-bottom: 10px;
}

.error-not-space {
  color: red;
}

.defaultClass{
  color: #3c4858;
}
.defaultClass option{
  color: #3c4858 !important;
}

.selectedCurrent{
  color: var(--s-200);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.selectedCurrent option{
  color: #3c4858 !important;
}
</style>
