<template>
  <woot-modal class="no-products-container" :show="showModal" :on-close="onClose" size="modal-big">
    <woot-modal-header
      :header-title="$t('CONVERSATION.CATALOG_PRODUCT.MODAL.TITLE')"
      :header-content="$t('CONVERSATION.CATALOG_PRODUCT.MODAL.SUB_TITLE')"
    />

    <div i class="input-container  padding-input " :class="{ 'is-focused': isInputFocused }">
      <div class="icon-container">
        <fluent-icon icon="search" class="icon contentSvg" aria-hidden="true" />
      </div>
      <input
        v-model="nameProduct"
        type="search"
        class="field-search"
        :placeholder="
          $t('CONVERSATION.CATALOG_PRODUCT.MODAL.PLACEHOLDER_SEARCH_NAME')
        "
        @focus="isInputFocused = true"
        @blur="isInputFocused = false"
      />
    </div>
    <div v-if="lengthCatalog">
      <p class="not-content">{{ $t('CONVERSATION.CATALOG_PRODUCT.MODAL.NO_PRODUCTS') }}</p>
    </div>
    <div v-if="!lengthCatalog" class="template__list-container" @scroll="handleScroll">
      <div
        v-for="product in dataValue"
        :key="product.id"
      >
      <div class="template__list-item">
        <div 
        @click="sendProduct(product)"
        v-html="renderProductContent(product)"
        />
        <div>
          <div class="container-image-catalog">
            <img class="image-catalog"  :src="product.url_image" :alt="product.name_product">
          </div>
        </div>
      </div>
        <hr>
      </div>
      <div v-if="loadingMore" class="text-center">
        <span class="spinner" />
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import agentMixin from '../../../mixins/agentMixin';
import adminMixin from '../../../mixins/isAdmin';

export default {
  mixins: [agentMixin, adminMixin],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nameProduct: '',
      isInputFocused: false,
      loadingMore: false,
      page: 1,
      allProductsLoaded: false,
      dataValue: []
    };
  },
  computed: {
    ...mapGetters({
      getProducts: 'catalog/getProducts',
    }),
    lengthCatalog(){
      return this.dataValue.length === 0
    },
  },
  watch: {
    async nameProduct(newName) {
      this.page = 1;
      this.allProductsLoaded = false;
      this.dataValue = await this.fetchCatalogProducts({
        page: this.page,
        hookId: null,
        name: newName,
      });
      this.isInputFocused = true;
    },
    async showModal(newValue) {
      if (newValue) {
        this.page = 1;
        this.allProductsLoaded = false;
        this.nameProduct = '';
       this.dataValue =  await this.fetchCatalogProducts({
          page: this.page,
          hookId: null,
          name: this.nameProduct,
        });
      }
    }
  },

  methods: {
    ...mapActions({
      fetchCatalogProducts: 'catalog/fetchCatalogProducts',
    }),
    sendProduct(product) {
      let content = product.settings.template;
      content = content
        .replace(/{{product.name_product}}/g, `${product.name_product}`)
        .replace(/{{product.url_image}}/g, `${product.url_image}`)
        .replace(/{{product.link_product}}/g, `${product.link_product}`);
      this.$emit('handle-send-message', content);
      return content;
    },
    renderProductContent(product) {
      let content = product.settings.template;
      content = content
        .replace(
          /{{product.name_product}}/g,
          `<div class="product-info" style="width:50%;"> 
            <p style="font-weight: bold; font-size:1.2rem;">${product.name_product}</p>
          </div>`
        )
        .replace(
          /{{product.link_product}}/g,
          `<div class="product-link">
            <a href="${product.link_product}" target="_blank">${product.link_product}</a>
          </div>`
        )
        .replace(
          /{{product.url_image}}/g,
          `

          `
        );
      return content;
    },
    onClose() {
      this.$emit('on-close');
    },
    async handleScroll(event) {
      const bottomOfWindow =
        Math.ceil(event.target.scrollTop + event.target.clientHeight) >=
        event.target.scrollHeight - 10;
      if (bottomOfWindow && !this.loadingMore && !this.allProductsLoaded) {
        await this.loadMoreProducts();
      }
    },
    async loadMoreProducts() {
      this.loadingMore = true;
      try {
        this.page = this.page + 1;

        const newProducts = await this.fetchCatalogProducts({
          page: this.page,
          hookId: null,
          name: this.nameProduct,
        });

        this.dataValue = [
          ...this.dataValue,
          ...newProducts
        ]
        
      } catch (error) {
        console.error(error);
      }finally{
        this.loadingMore = false;

      }
    },
  },
};
</script>


<style lang="css" scoped>
.product-content img.image-catalog {
  width: 20px !important;
  height: 20px !important;
  
}
.container-image-catalog{
  width: 50%;
  float: right;
}
.product-content {
  display: flex;
  align-items: center;
  padding: 10px;
}

.product-link-class {
  color: blue;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
.icon-container {
  position: relative;
  top: 29px;
  left: 5px;
  width: 20px;
  height: 20px;
}
input[type='search'] {
  box-sizing: border-box;
  padding: 12px 26px;
}
.image-catalog {
  width: 200px;
}
.padding-input {
  margin: 0 20px;
}
.flex-content-value{
  width: 100%;
}
.flex-content-value p{
  font-weight: bold;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-one);
}

.templates__list-search {
  align-items: center;
  background-color: var(--s-25);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--s-100);
  display: flex;
  margin-bottom: var(--space-one);
  padding: 0 var(--space-one);

  .search-icon {
    color: var(--s-400);
  }

  .templates__search-input {
    background-color: transparent;
    border: var(--space-large);
    font-size: var(--font-size-mini);
    height: unset;
    margin: var(--space-zero);
  }
}
.template__list-container {
  overflow-y: auto;
  max-height: 31vh;
  background-color: var(--s-25);
  border-radius: var(--border-radius-medium);
  overflow-y: auto;
  padding: var(--space-one);
  .template__list-item {
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    padding: var(--space-one);
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:hover {
      background-color: var(--w-50);
    }

    .label-title {
      font-size: var(--font-size-small);
    }

    .label-category {
      margin-top: var(--space-two);

      span {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bold);
      }
    }

    .label-body {
      font-family: monospace;
    }
  }
}
.strong {
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-bold);
}

hr {
    border-bottom: 1px solid var(--s-100);
}

.modal-mask {
      background-color: rgba(0, 0, 0, .4) !important;
}
.no-products-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--s-400);
}
.not-content{
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 100px 0;
  }
::v-deep {
  .modal-container {
    width: 60%;
    height: 50%;
    overflow: hidden;
  }
}

::v-deep {
  .page-top-bar {
    padding: 2.2rem 2.2rem 0;
  }
}
</style>
