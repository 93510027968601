import {
  CONVERSATION_PRIORITY_ORDER,
  MESSAGE_TYPE,
} from 'shared/constants/messages';
import { applyPageFilters } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

// getters
const getters = {
  getAllConversations: ({ allConversations, chatSortFilter }) => {
    const comparator = {
      latest: (a, b) => b.last_activity_at - a.last_activity_at,
      sort_on_created_at: (a, b) => a.created_at - b.created_at,
      sort_on_priority: (a, b) => {
        return (
          CONVERSATION_PRIORITY_ORDER[a.priority] -
          CONVERSATION_PRIORITY_ORDER[b.priority]
        );
      },
    };

    return allConversations.sort(comparator[chatSortFilter]);
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: (_state, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { attachments } = selectedChat;
    return attachments;
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getMineChats: (_state, _, __, rootGetters) => activeFilters => {
    const currentUserID = rootGetters.getCurrentUser?.id;

    return _state.allConversations.filter(conversation => {
      const { assignee, sender } = conversation.meta;
      const isAssignedToMe =
        (sender && sender.agent_id === currentUserID) ||
        (assignee && assignee.id === currentUserID) ||
        (activeFilters?.contact_id &&
          activeFilters?.contact_id === conversation.contact_id);
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getStatusFilter: _state => {
    return _state.statusFilterConversationAndContact;
  },
  getUnAssignedChats: _state => (
    activeFilters,
    permissions = null,
    isAgent = null
  ) => {
    return _state.allConversations.filter(conversation => {
      const isUnAssigned = !conversation.meta.assignee;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isPartOfMyTeam = conversation?.meta?.team?.is_member !== false;

      if (permissions || isAgent) {
        return isPartOfMyTeam && isUnAssigned && shouldFilter;
      }
      return isUnAssigned && shouldFilter;
    });
  },
  getAllStatusChats: _state => (
    activeFilters,
    permissions = null,
    currentId = null
  ) => {
    return _state.allConversations.filter(conversation => {
      const assigneeID =
        conversation?.last_non_activity_message?.conversation?.assignee_id;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isPartOfMyTeam = conversation?.meta?.team?.is_member !== false;

      if (permissions) {
        return (
          shouldFilter &&
          (isPartOfMyTeam || assigneeID === currentId || assigneeID === null)
        );
      }
      return shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
};

export default getters;
